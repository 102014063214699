import { FC, useEffect, useRef, useState } from "react";
import { TPage, getPage } from "../services/page.service";
import { Box, Heading, Image, Stack } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

const PagesComponent: FC<{ id: number }> = ({ id }) => {
    const mountedRef = useRef(true);
    const [currentPage, setCurrentPage] = useState<TPage | null>(null);

    useEffect(() => {
        (async () => {
            setCurrentPage(await getPage(id));
        })();
        return () => {
            mountedRef.current = false;
        };
    }, []);
    return (
        <>
            <style>
                {`
                    body {
                        overflow: scroll;
                    }
                    h2 {
                        font-weight: 700;
                        font-size: 32px;
                        margin-top: 20px;
                    }
                    
                    h3 {
                        font-weight: 700;
                        font-size: 26px;
                        margin-top: 20px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 26px;
                    }
                `}
            </style>
            {currentPage && (
                <>
                    <Box
                        display="flex"
                        height="80px"
                        flexDirection="row"
                        position="relative"
                        bg="transparent"
                    >
                        <Box
                            height={[70, 116]}
                            width={[140, 235]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            backgroundColor="#FFFFFF"
                            position="absolute"
                            top={0}
                            left={0}
                            borderBottomRightRadius="10px"
                        >
                            <Box as="a" href="/">
                                <Image
                                    alt="IVTMOBILITY"
                                    src="/images/logo.svg"
                                    width={[102, 150]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Stack maxW={"850px"} mx={"auto"} px={"50px"}>
                        <Heading
                            as={"h1"}
                            textAlign={"center"}
                            textTransform={"uppercase"}
                            mt={"50px"}
                        >
                            {currentPage.titre}
                        </Heading>
                        <Box my={"30px"}>
                            <ReactMarkdown>{currentPage.contenu}</ReactMarkdown>
                        </Box>
                    </Stack>
                </>
            )}
        </>
    );
};

export default PagesComponent;
